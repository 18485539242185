import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36')
];

export const server_loads = [0];

export const dictionary = {
		"/": [6],
		"/auth/callback": [33,[5]],
		"/auth/login": [34,[5]],
		"/auth/password-recovery": [35,[5]],
		"/auth/touchpanel": [36,[5]],
		"/(app)/booking": [7,[2,3]],
		"/(app)/buildingmanager": [10,[2]],
		"/(app)/building": [~8,[2]],
		"/(app)/building/[id=uuid]": [~9,[2]],
		"/(app)/catering": [11,[2]],
		"/(app)/catering/categories": [12,[2]],
		"/(app)/catering/menus": [13,[2]],
		"/(app)/catering/products": [14,[2]],
		"/(app)/catering/statistics": [15,[2]],
		"/(app)/digital-twin": [16,[2]],
		"/(app)/home": [~17,[2]],
		"/(app)/home/[id=uuid]": [~18,[2]],
		"/(app)/monitoring": [19,[2]],
		"/(app)/my-controls": [~20,[2]],
		"/(app)/my-controls/area/[id=uuid]": [~21,[2]],
		"/(app)/my-controls/building/[id=uuid]": [~22,[2]],
		"/(app)/my-controls/floor/[id=uuid]": [~23,[2]],
		"/(app)/services": [24,[2]],
		"/(app)/settings": [~25,[2]],
		"/(app)/suppliers": [26,[2]],
		"/(app)/tickets": [27,[2]],
		"/(app)/usermanagement": [~28,[2,4]],
		"/(app)/usermanagement/groups": [29,[2,4]],
		"/(app)/usermanagement/roles": [30,[2,4]],
		"/(app)/usermanagement/users": [31,[2,4]],
		"/(app)/visitors": [32,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';